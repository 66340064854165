<template>
    <ValidationObserver ref="validator" slim>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\reports\\edit.5220') }}</v-toolbar-title>
            </v-toolbar>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.title"
                    :label="$t('src\\views\\reports\\edit.8556')"
                    outlined
                    :error-messages="errors[0]"
                    @input="updateTitle"
                />
            </ValidationProvider>
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.code"
                    :label="$t('src\\views\\reports\\edit.url')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <v-img :src="imageSrc(entity.picture)" />
            <v-file-input
                :label="$t('src\\views\\reports\\edit.4973')"
                outlined
                prepend-icon="mdi-image"
                @change="setPicture"
            />
            <ValidationProvider v-slot="{ errors }" rules="required">
                <v-text-field
                    v-model="entity.preview"
                    :label="$t('src\\views\\reports\\edit.2338')"
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider>
            <!-- <v-text-field
                v-model="entity.youtube"
                :label="$t('src\\views\\news\\edit.youtube')"
                outlined
            /> -->
            <v-row class="mb-5">
                <v-col>
                    <h4>{{ $t('src\\views\\reports\\edit.958') }}</h4>
                    <template v-for="(content, index) in entity.contents">
                        <div class="news__content__block" :key="index">
                            <div v-if="content.type === NEWS_CONTENT_TYPE.TEXT">
                                <tiptap-vuetify
                                    v-model="content.value"
                                    :placeholder="$t('src\\views\\reports\\edit.520')"
                                    :extensions="extensions"
                                />
                            </div>
                            <div v-if="content.type === NEWS_CONTENT_TYPE.PICTURE">
                                <v-img :src="imageSrc(content.value)" />
                                <v-file-input
                                    :label="$t('src\\views\\reports\\edit.6333')"
                                    outlined
                                    prepend-icon="mdi-image"
                                    @change="setContentPicture({ index, value: $event })"
                                />
                            </div>
                            <div v-if="content.type === NEWS_CONTENT_TYPE.YOUTUBE">
                                <v-text-field
                                    v-model="content.value"
                                    :label="$t('src\\views\\reports\\edit.youtube')"
                                    outlined
                                />
                            </div>
                            <v-row
                                align="center"
                                justify="end"
                                class="my-2 mx-0">
                                <v-btn
                                    color="error"
                                    @click="removeContent(index)">{{ $t('src\\views\\reports\\edit.8570') }}</v-btn>
                            </v-row>
                        </div>
                    </template>
                    <v-row
                        align="center"
                        justify="space-between"
                        class="my-2 mx-0">
                        <v-btn
                            color="primary"
                            @click="addContent(NEWS_CONTENT_TYPE.TEXT)">{{ $t('src\\views\\reports\\edit.6004') }}</v-btn>
                        <v-btn
                            color="primary"
                            @click="addContent(NEWS_CONTENT_TYPE.PICTURE)">{{ $t('src\\views\\reports\\edit.808') }}</v-btn>
                        <v-btn
                            color="primary"
                            @click="addContent(NEWS_CONTENT_TYPE.YOUTUBE)">{{ $t('src\\views\\reports\\edit.6381') }}</v-btn>
                    </v-row>
                </v-col>
            </v-row>
            <!-- <ValidationProvider v-slot="{ errors }" rules="required">
                <v-textarea
                    v-model="entity.text"
                    :label="$t('src\\views\\reports\\edit.3770')"
                    auto-grow
                    outlined
                    :error-messages="errors[0]"
                />
            </ValidationProvider> -->

            <v-row
                align="center"
                justify="space-between"
                class="mx-0">
                <v-btn
                    :disabled="!valid"
                    color="primary"
                    @click="save">{{ $t('src\\views\\reports\\edit.7048') }}</v-btn>
                <v-btn
                    v-if="entity._id"
                    color="error"
                    @click="remove">{{ $t('src\\views\\reports\\edit.2900') }}</v-btn>
            </v-row>
        </v-form>
    </ValidationObserver>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Image, Code, CodeBlock, Paragraph, BulletList, OrderedList, ListItem, Link, Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { imageSrc, translit } from '../../helpers';
    import { NEWS_CONTENT_TYPE } from '../../vars';

    export default {
        name: 'NewsEdit',
        components: {
            ValidationProvider,
            ValidationObserver,
            TiptapVuetify,
        },
        data: () => ({
            NEWS_CONTENT_TYPE,
            imageSrc,
            valid: true,
            extensions: [
                History,
                [Heading, {
                    options: {
                        levels: [2, 3, 4]
                    }
                }],
                Bold,
                Underline,
                Strike,
                Italic,
                ListItem,
                BulletList,
                OrderedList,
                HorizontalRule,
                Link,
            ],
        }),
        computed: {
            ...mapState('reports', ['entity']),
        },
        async mounted() {
            if(this.$route.params.id) {
                await store.dispatch('reports/get', { id: this.$route.params.id });
            } else {
                await store.commit('reports/CLEAR_ENTITY');
            }
        },
        methods: {
            async setPicture(file) {
                this.entity.picture = await store.dispatch('files/upload', { file });
            },
            updateTitle(title) {
                this.entity.code = translit(title);
            },
            addContent(type) {
                this.entity.contents.push({
                    type,
                    value: ''
                })
            },
            removeContent(index) {
                this.entity.contents.splice(index, 1);
            },
            async setContentPicture({ index, value }) {
                this.entity.contents[index].value = await store.dispatch('files/upload', { file: value });
            },
            async save() {
                this.valid = await this.$refs.validator.validate();
                if(this.valid){
                    await store.commit('reports/SET_ENTITY', this.entity);
                    const response = await store.dispatch('reports/save');
                    if(response.error) {
                        alert(response.error);
                    } else {
                        await this.$router.push({ name: 'reports-list' });
                    }
                }
            },
            async remove() {
                await store.dispatch('reports/delete', { id: this.entity._id });
                await this.$router.push({ name: 'reports-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
    .news__content__block {
        margin: 10px 0;
        padding: 20px;
        border: 1px solid #cecece;
        border-radius: 4px;
    }
</style>
